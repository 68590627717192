/* Login */
#layoutAuthentication {
    background-color: #e9e9e9;
    background-image: url("../../img/painel/eloca-pattern.png");
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 50vh;

    .card-header {
        text-align: center;
        background-color: #fff;
        img {
            max-width: 250px;
        }
    }
    .card-footer {
        background-color: #fff;
    }
    .login__page {
        height: 90vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #layoutAuthentication_footer {
        background-color: $color-eloca-secondary;
        color: #fff;
    }
}

/* Settings */
.nav-pills {
    .nav-link {
        color: $color-secondary;
        font-size: 0.9rem;
        padding: 1rem;
    }

    &.nav-pills-subitems {
        height: 100%;
        min-height: 76vh;
        background-color: #ffffff;

        &:first-child {
            padding-top: 0.2rem;
        }

        .nav-link {
            padding: 0.75rem 1rem;
            margin-bottom: 2px;
            border-left: 3px solid transparent;
            border-radius: 0;
            background-color: #ffffff;
            width: 100%;

            &.active {
                background-color: #f3f4f5;
                border-left: 3px solid #222529;
                color: #222529;
                font-weight: bold;
            }
            &:hover {
                background-color: #aaabac30;
            }
        }
    }
}

/* Clientes */
.clientes__detalhes {
    margin-bottom: 2rem;
    .card-footer {
        .button-right {
            float: right;
        }
    }
    .modal-content {
        text-transform: none;
        font-weight: normal;
    }
}
.client__header {
    .client__main-info {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .buttons-action-top {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        gap: 8px;
        margin-top: 0.5rem;
    }
    .client__photo {
        cursor: pointer;
        width: 150px;
        margin-right: 1rem;
        height: 150px;
        background: #fbfbfb;
        overflow: hidden;
        border-radius: 100px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 5px solid #fbfbfb;

        img {
            height: 100%;
            width: auto;
            max-width: auto;
        }
    }
    .client__icon {
        @extend .client__photo;

        width: 100px;
        height: 100px;

        border-color: #e6e6e6;

        svg {
            color: #e6e6e6;
        }
    }
}
.client__score {
    text-align: center;
    .score__value {
        background-color: #ffffff;
        border-radius: 50%;
        height: 200px;
        text-align: center;

        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        font-weight: bold;
        font-size: 1rem;

        .total {
            color: #666;
            font-weight: 300;
        }

        .grafico {
            position: absolute;
        }
    }
    .score__classificacao {
        text-align: center;
        font-weight: bold;
        padding-top: 1rem;
    }
}
.score__risco {
    .risco {
        font-weight: bold;
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
    }
}

.risco_cabecalho {
    cursor: pointer;
}

.score__totais {
    border-radius: 15px;
    padding: 0.25rem 1.25rem;
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
    min-height: 50px;
    color: #856404;
    padding-top: 10px;
    background-color: #fff3cd
}
.score__faturamento {
    .faturamento {
        font-weight: bold;
        margin-top: 1rem;
        font-size: 1.25rem;
    }

    .valor {
        font-weight: bold;
        font-size: 1.25rem;
    }
}
.card--score {
    .card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

// Score
.semi-donut {
    --percentage: 0;
    --fill: #ff0;
    width: 300px;
    height: 150px;
    position: relative;
    color: #fff;
    font-size: 22px;
    font-weight: 600;
    overflow: hidden;
    color: var(--fill);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-sizing: border-box;
    &:after {
        content: "";
        width: 300px;
        height: 300px;
        border: 36px solid;
        border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) var(--fill)
            var(--fill);
        position: absolute;
        border-radius: 50%;
        left: 0;
        top: 0;
        box-sizing: border-box;
        transform: rotate(calc(1deg * (-45 + var(--percentage) * 1.8)));
        animation: fillAnimation 1s ease-in;
    }
}

@keyframes fillAnimation {
    0% {
        transform: rotate(-45deg);
    }
    50% {
        transform: rotate(135deg);
    }
}

@keyframes fillGraphAnimation {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
}

/* Produtos */

.faixas-precos__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .faixas-preco {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.support-link {
    color: $color-primary;
    cursor: pointer;
}

.ajuda {
    .bloco {
        &:hover {
            transition: all 0.3s;
            box-shadow: 3px 3px 10px #d7d5d5;
        }
    }
    .bloco-link {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        padding: 1rem;
    }
    .bloco-imagem {
        width: 70px;
        min-height: 70px;
        background-color: #f3f4f5;
        border-radius: 4px;
        padding: .5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.pagina-ajuda {
    margin-bottom: 2rem;
    font-size: 1rem;

    img {
        max-width: 100%;
    }
    .tab-content {
        padding-bottom: 2rem;
    }
    .thumb {
        max-width: 400px;
        padding: 1.5rem 0;

        &--large {
            max-width: 700px;
        }
    }
    .thumb-border {
        border: 2px solid #ddd;
    }
    .iframe-video {
        width: 700px;
        max-width: 100%;
        height: 400px;
    }
    .btn-wizard {
        display: flex;
        align-items: center;
    }
    .contract__list {
        border: 2px solid #f7f6f6;
        max-width: 800px;

        .title {
            font-weight: bold;
            background-color: #fbfbfb;
            padding: 0.5rem 1rem;
        }
        .info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.25rem 1rem;
            border-bottom: 1px solid #ddd;
        }
    }
}

.ajuda-sidebar {

    .titulo {
        font-size: 1.25rem;
        padding: .25rem 0;
    }

    .nav-link {
        color: $color-eloca-secondary;
        padding: .25rem .35rem;
        font-size: .85rem;
        border-radius: .25rem;
        width: fit-content;
        transition: all ease-in-out .3s;

        &:hover {
            opacity: .9;
            color: #4d5053;
            background: #e4e6e8;
        }

        &.active {
            font-weight: 500;
            color: #4d5053;
            background: #e4e6e8;
        }
    }

    .grupo {
        margin-top: 1.5rem;
        margin-bottom: 0;
        display: inline-flex;
        align-items: center;
        .nav-link {
            font-size: 1rem;
        }
        &:first-child {
            margin-top: 0;
        }
    }
}

.ajuda-rodape {
    padding: 1.5rem 2rem;
    border-radius: 8px;
    background-color: #ffffff;
    margin-bottom: 3rem;
    margin-top: 2rem;
}

// Todas as Lojas

.icon-divider {
    width: 3px !important;
    color: #ccc;
    margin: 0 3px;
}

.table__cs {
    .loja {
        background-color: #fcfcfc;
    }
    .table-custom {
        .tr {
            padding: 0;
        }
    }
}

.custom-calendar {
    .tarefa {
        overflow: hidden;
        max-width: 100%;
        text-overflow: ellipsis;
        display: inline-block;
        padding: 0.35rem;
        font-size: 0.65rem;
        font-weight: 600;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 0.25rem;
        margin-bottom: 0.15rem;
        &:hover {
            color: #dddddd;
        }
    }
    .month {
        width: 100%;
        text-align: center;
        ul {
            margin: 0;
            padding: 0;
        }

        li {
            list-style: none;
            font-weight: 600;
            text-transform: uppercase;
        }

        .prev {
            float: left;
        }

        .next {
            float: right;
        }
    }

    .weekdays {
        margin: 0;
        padding: 10px 0;
        background-color: #ddd;
        li {
            display: inline-block;
            width: 13.7%;
            color: #666;
            text-align: center;
            font-size: 0.85rem;
        }
    }
    .day {
        font-size: 12px;
        margin-right: 0.5rem;
    }
    .days {
        background: #ffffff;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;

        li {
            list-style-type: none;
            display: flex;
            align-items: baseline;
            justify-content: flex-start;
            flex-direction: column;
            width: 14.27%;
            padding: 0.5rem;
            border: 1px solid #eeeeee;
            height: 120px;

            &.active {
                background: #3ac99b47;
            }
        }
    }

    @media screen and (max-width: 720px) {
        .weekdays li,
        .days li {
            width: 13.1%;
        }
    }

    @media screen and (max-width: 420px) {
        .weekdays li,
        .days li {
            width: 12.5%;
        }
        .days li .active {
            padding: 2px;
        }
    }

    @media screen and (max-width: 290px) {
        .weekdays li,
        .days li {
            width: 12.2%;
        }
    }
}


// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .nav-pills {
        &.nav-pills-subitems {
            flex-direction: row;
        }
    }

    .clientes__detalhes {
        .btn {
            width: 100%;
            margin-left: 0 !important;
            margin-top: 0.5rem;
        }
        .card-footer {
            .button-right {
                float: none;
            }
        }
    }
    .client__header {
        .client__main-info {
            flex-direction: column;
            align-items: flex-start;
        }
        .client__photo {
            cursor: pointer;
            width: 100px;
            height: 100px;
            text-align: center;
            margin: 0 auto;
        }
    }

    #layoutAuthentication {
        background-size: 25vh;
        .card-header {
            img {
                max-width: 200px;
            }
        }
        .login__page {
            margin-top: 3rem;
            align-items: flex-start;
            height: auto;
        }
    }

    .pagina-ajuda {
        .tab-content {
            h1,
            h2 {
                margin-top: 1rem;
                font-size: 1.25rem;
            }
        }
        .iframe-video {
            width: 300px;
            max-width: 100%;
            height: 280px;
        }
        .btn-wizard {
            flex-direction: column;
            align-items: flex-start;
            a {
                width: 100%;
            }
        }
        .contract__list {
            .info {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }

    .client__score {
        .score__value {
            height: 120px;
        }
    }
    .semi-donut {
        width: 200px;
        height: 100px;
        &::after {
            width: 200px;
            height: 200px;
            border-width: 24px;
        }
    }
    .card--score {
        .card-header {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    .faixas-precos__footer {
        align-items: flex-start;
        flex-direction: column;
    }
}

.canvas-avatar {
    height: 200px;
    width: 250px;
    background-color: #ffffff;
    cursor: default;
    border: 0;
}

.video-avatar {
    height: 200px;
    cursor: default;
    border: 0;
}

.mr-6,
.mx-6 {
    margin-right: 2.3rem !important;
}
