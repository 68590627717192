$color-primary: #3000d9;
$color-primary-light: #1960a7;
$color-primary-darker: #040143;
$color-primary-lighter: #5392d1;
$black: #000000;
$gray: #7c7c7c;
$gray2: #6c757d;
$gray3: #ececec;

$color-secondary: #474a4d;
$color-secondary-bg: #474a4d2b;
$color-tertiary: #e8198d;
$color-complementary: #f3b512;
$color-link: #007bff;

$color-dark: #040143;
$color-light: #ffffff;
$color-accent: #2ececc;
$color-media-assinatura: #ff9933;

$color-success: #01cc80;
$color-success-200: #00a771;
$color-success-dark: #00815D;
$color-success-bg: #4caf5020;

$color-warning: #e39909;
$color-info: #017ac2;
$color-error: #b12f1f;
$color-error-100: #ee5b4f;
$color-error-200: #db4437;
$color-ok: #1fa578;

$color-eloca: #01cc80;
$color-eloca-secondary: #121220;

$color-youtube: #ff1300;
$color-facebook: #4064ac;
$color-instagram: linear-gradient(
    to left,
    hsl(37, 97%, 70%),
    hsl(329, 70%, 58%)
);
$color-linkedin: #0b66c2;
$color-pix: #32bcad;
$color-whatsapp: #29b43f;
$color-whatsapp-dark: #48974b;
$color-whatsapp-button: #63737c;
$color-whatsapp-button-dark: #8696a0;
$color-jivochat: #008d3a;
$color-facebook: #016af2;

// Temas
// Default
$theme-bg: #343a40;
$theme-text: #ddd;

$theme-sidenav-bg: #212529;
$theme-sidenav-text: rgba(255, 255, 255, 0.9);
$theme-sidenav-link: rgba(255, 255, 255, 0.5);

$theme-text1: rgba(255, 255, 255, 0.5);

// Dark
$theme-dark-bg: #343a40;
$theme-dark-bg-100: #222529;
$theme-dark-bg-hover: #3a3d42;

$theme-dark-bg-200: #2b2e32;
$theme-dark-bg-300: #2a2c2f;
$theme-dark-bg-400: #3a3d42;
$theme-dark-bg-500: #121212;
$theme-dark-bg-600: #131313;
$theme-dark-bg-700: #2e3238;

$theme-dark-text: #ddd;
$theme-dark-danger-light: #e04d5c;
$theme-dark-danger: #dd3142;


$theme-dark-sidenav-bg: #212529;
$theme-dark-sidenav-text: rgba(255, 255, 255, 0.9);
$theme-dark-sidenav-link: rgba(255, 255, 255, 0.5);

$theme-dark-text1: rgba(255, 255, 255, 0.5);

$theme-dark-link: #429eff;
$theme-dark-primary: #005ec2;
$theme-dark-primary-dark: #004fa3;

$theme-dark-success-light: #02ca81;
$theme-dark-success: #018454;
$theme-dark-success-dark: #01603d;

// Light

$theme-light-bg: #ddd;
$theme-light-bg-100: #222529;
$theme-light-text: #343a40;

$theme-light-sidenav-text: #303030;
$theme-light-sidenav-bg: rgb(251 251 251);
$theme-light-sidenav-link: #666666;

$theme-light-text1: rgba(255, 255, 255, 0.5);

$theme-light-color-primary: #5578fd;

// Pedido
$color-status-pendente: #777777;
$color-status-pendente-bg: #77777720;
$color-status-processando: #f3b30c;
$color-status-processando-bg: #f3b30c20;
$color-status-devolucao: #e8198d;
$color-status-devolucao-bg: #e8198d20;
$color-status-concluido: #1960a7;
$color-status-concluido-bg: #1960a720;
$color-status-cancelado: #777;
$color-status-cancelado-bg: #77777720;
$color-status-orcamento: #2ececc;
$color-status-orcamento-bg: #2ececc20;
$color-status-pago: #4caf50;
$color-status-pago-bg: #4caf5020;

// Assinatura
$color-status-iniciada: #f3b30c;
$color-status-iniciada-bg: #f3b30c20;
$color-status-ativa: #2ececc;
$color-status-ativa-bg: #2ececc20;
$color-status-suspensa: #777777;
$color-status-suspensa-bg: #77777720;
$color-status-cancelada: #777777;
$color-status-cancelada-bg: #77777720;
$color-status-atrasada: #e8198d;
$color-status-atrasada-bg: #e8198d20;
$color-status-encerrada: #1960a7;
$color-status-encerrada-bg: #1960a720;
