$eloca-primary: #00A071;

$color-whatsapp: #29b43f;
$color-whatsapp-dark: #48974b;


/* Botoes */
html {
    overflow: unset !important;
}
body {
    overflow: auto !important;
}

.lp-btn {
    border-radius: 10px;
    font-weight: 400;
    padding: .8rem;
    font-size: 10px;

    &:hover {
        opacity: 0.8;
        border-radius: 10px;
    }
}
.lp-btn-sm {
    padding: 0.5rem 1rem;
}

.btn-tooltip {
    background-color: transparent;
    border: none;
}
.custom-tooltip {
    --bs-tooltip-bg: #ffffff;
    --bs-tooltip-color: #424242;
    --bs-tooltip-padding-x: 1rem;
    --bs-tooltip-padding-y: 1rem;
    --bs-tooltip-border-radius: 1rem;
    --bs-tooltip-margin: 0;
    --bs-tooltip-arrow-width: 1rem;
    --bs-tooltip-arrow-height: 0;
    --bs-tooltip-opacity: 1;
    box-shadow: 1px 3px 20px #cccccc;
    font-size: 1rem;
}

.lp-title {
    color: $eloca-primary;
}

.subtitulo {
   color: #9A9A9F;
   margin-bottom: 1.5rem;
}
.fs-0 {
    font-size: 3rem;
    line-height: 3rem;
}
.fs-7 {
    font-size: .8rem;
}
.fc-destaque {
    color: $eloca-primary;
}

.recursos {
    color: #424242;
    padding: 2rem;
    border-radius: 1rem;
    text-align: center;
}

.recurso {
    text-align: left;
    color: #7f7f7f;
    padding: 2rem;
    .titulo {
        text-transform: uppercase;
        color: #424242;
    }

    img {
        margin-right: 1rem;
        max-width: 90px;
    }
    &:hover {
        background-color: #f8e7f0;
        color: $eloca-primary;
    }
}
.btn-saiba-mais {
    margin-top: 1rem;
    img {
        max-width: 20px;
    }
}
.header__eloca {
    background-color: #e9e9e9f0 !important;
    border-bottom: 1px solid #9A9A9F80;

    .navbar-custom {
        background-color: transparent !important;
    }
}

.bloco-col-5 {
    display: flex;
    .custom-col {
        width: 20%;
    }
}

.btn-modal-ferramenta {
    position: relative;
}

.link-demo {
    display: none;
    position: absolute;
    transition: all 0.5s ease;
    right: 0;
    left: 0;
}

.ferramenta {
    display: flex;
    align-items: center;
    border: 1px solid #e9e9e9f0;
    border-radius: 25px;
    padding: 1rem;
    margin: 1rem 0;
    transition: all 0.5s ease;
    overflow: hidden;
    &:hover {
        box-shadow: 1px 1px 13px #e9e9e9;

        img {
            scale: 1.2;
            transition: all 0.5s ease;
        }

        .link-demo {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            background-color: rgb(255 252 252 / 50%);
            height: 100%;
            z-index: 2;
            font-weight: 400;
            font-size: .9rem;
            &:hover {
                text-decoration: underline;
            }
            &::before {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-left: 6px solid $eloca-primary;
                border-right: 0;
                margin: 0 .5rem .45rem 0;
            }
        }
    }
}

.blocos-planos {
    width: 100%;
    padding: .8rem 1.5rem;
    margin-top: 3rem;
    background-color: #fff;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.bloco-planos {
    background-color: #fff;
    height: auto;
    position: -webkit-sticky;
    position: sticky;
    top: 70px;
    z-index: 1;
}

.lp-planos {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    border-radius: 0.5rem;

    .destaque {
        border: 1px solid #f2f2f2;
    }
    .card-taxas {
        background-color: #f4f4f4;
        border: none;
    }
    .check {
        svg {
            color: $eloca-primary;
            width: 20px;
        }
    }

    .features-block {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .feature--first {
            border-top-left-radius: 1rem;
        }
        .feature--last {
            border-bottom-left-radius: 1rem;
        }
    }

    .features {
        width: 20%;
        background-color: #f4f4f4;
        margin: 0 0.5rem 0.5rem 0.5rem;

        .feature {
            border-bottom: 1px solid #fff;
            padding: 0.5rem;
            font-size: 1rem;
            min-height: 70px;
            text-align: center;
            overflow: hidden;

            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                height: auto;
            }

            &-obs {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
            }
            .obs {
                font-weight: bold;
                margin-top: 0.25rem;
                font-size: 0.8rem;
                a {
                    color: #333;
                }
            }
        }
    }
    .features-row {
        @extend .features;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        background-color: #fff;

        .feature {
            width: 100%;
            margin: 0 0.5rem;
            background-color: #f4f4f4;
            height: 64px;
            &.empty {
                background-color: #fff;
                border: none;
            }
        }

        &.buttons {
            .feature {
                border-bottom: none;
                border-bottom-left-radius: 1rem;
                border-bottom-right-radius: 1rem;
                padding: 0.5rem;
            }
        }
    }
    .features-subtitle {
        width: 100%;
        background-color: #fff;
        font-weight: 600;

        .feature {
            border-bottom: none;
            font-size: 1rem;
            text-align: left;
            padding-left: 0;
            background-color: #fff;
            justify-content: flex-start;
        }
    }

    .plano-atual {
        text-transform: uppercase;
        font-weight: bold;
        color: $eloca-primary;
    }
}

.planos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 2rem;
    color: #424242;
    background: #e9e9e9;
    background-image: url("../../../../../img/eloca/lp-planos/bg-eloca-cinza.png");
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: auto 180px;
    position: relative;

    .plano {
        background-color: #ffffff;
        border: 1px solid #e9e9e9;
        width: 100%;
        padding: 2rem;
        border-radius: 25px;
        position: relative;
        overflow: hidden;
        height: 100% !important;
        transition: all .5s ease;

        &--destaque {
            background-color: #e9e9e9;
        }
        &:hover {
            box-shadow: 1px 1px 13px #e9e9e9;
        }
    }
    .plano-conteudo {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
        height: 100%;
        margin-bottom: 2rem;
    }
    strong {
        color: $eloca-primary;
    }
    .texto-principal {
        padding: 9rem 0;
        text-align: center;
    }
    .nav-produtos-eloca {
        text-align: center;
        border-bottom: 1px solid #9A9A9F;
        a {
            position: relative;
            color: #9A9A9F;
            font-weight: 400;
            font-size: 1.15rem;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 2rem;
            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 3px;
                transition: all 0.3s ease;
            }
            &:hover {
                color: #333;
                &::after {
                    background-color: #333;
                }
            }
            &.active {
                position: relative;
                color: $eloca-primary;
                &::after {
                    background-color: $eloca-primary;
                }
            }
        }
    }
    .nome {
        font-size: 1rem;
        font-weight: 400;
        color: $eloca-primary;
    }
    .preco {
        font-size: 1.75rem;
        font-weight: 600;
    }

    .link-whatsapp {
        background-color: $color-whatsapp;
        color: #fff;
        border-radius: 50px;
        text-transform: uppercase;
        font-weight: bold;
        padding: 0.75rem 1rem;
        svg {
            width: 22px;
            margin-right: 7px;
        }
    }
    .check {
        svg {
            color: $eloca-primary;
            width: 20px;
        }
    }

}

// XL
@media (max-width: 1199.98px) {
    .lp-planos__pagina {
        .features-row {
            .feature {
                font-size: 0.8rem;
                min-height: 80px;
            }
        }
        .lp-btn {
            font-size: 0.9rem;
            padding: 0.5rem 1rem;
        }
    }
    .bloco-col-5 {
        display: flex;
        .custom-col {
            width: 25%;
        }
    }
}

// LG
@media (max-width: 991.98px) {
    .lp-planos__pagina {
        font-size: 0.9rem;
        .planos {
            .plano {
                margin: 0;
                border-radius: 0;
            }
        }
        .features-row {
            .feature {
                margin: 0;
                font-size: 0.8rem;
                min-height: 80px;
            }
        }
    }
    .bloco-planos {
        position: relative;
        top: 0;
    }

    .bloco-col-5 {
        .custom-col {
            width: 50%;
        }
    }
}

@media (max-width: 767.98px) {
    .lp-planos {
        .planos {
            .plano {
                height: auto !important;
                margin-bottom: 1rem;
            }
            .texto-principal {
                padding: 3rem 0 5rem 0;
            }
        }
        .popular {
            float: right;
            position: relative;
            margin-top: 0.3rem;
            margin-right: 0.3rem;
            border-radius: 0.5rem;
            padding: 0.25rem;
            font-weight: 600;
            text-transform: capitalize;
        }
        .nome {
            padding: 0;
            margin: 0;
            background-color: transparent;
        }
        .preco {
            font-size: 1.2rem;
        }
        .link-whatsapp {
            padding: 0.5rem 1rem;
            font-size: 0.9rem;
        }
        .accordion {
            .card-header {
                padding: 0;
                button {
                    color: #333;
                }
            }
        }
        .icon-sm {
            svg {
                width: 14px;
            }
        }
        .icon-xs {
            svg {
                width: 12px;
            }
        }
    }
    .bloco-col-5 {
        .custom-col {
            width: 100%;
        }
    }
    .recursos-container {
        overflow: auto;
    }
    .recursos-bloco {
        display: block;
        width: max-content;
        overflow-x: auto;
    }
}
