/* Card */
.card-shadow {
    border-color: #eef1f3;
    box-shadow: 3px 3px 10px #efefef;

    .card-header {
        background-color: #fbfbfb;
        border-bottom: 1px solid rgb(0 0 0 / 5%);
        h5 {
            margin-bottom: 0;
        }
    }
    .card-footer {
        border: none;
    }
}

.card-store {
    @extend .card-shadow;

    img {
        max-height: 100px;
    }
    .store-name {
        font-weight: bold;
        font-size: 1rem;
        margin-bottom: 1rem;
    }
    .item__details {
        margin: 0 auto;
    }
    .card-footer {
        background-color: #fff;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
    }
}

.card-aligned {
    @extend .card-shadow;
    .card-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        .btn {
            margin-top: 1rem;
        }
        .card-content {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: row;
            width: 100%;
        }
    }
    .card-icon {
        max-width: 150px;

        svg {
            max-height: 100px;
            max-width: 140px;
            min-height: 95px;
            min-width: 115px;
            padding: 1rem;
        }
    }
}

.card-order {
    .tag-status {
        white-space: nowrap;
    }
}

.card__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Header */
.sb-topnav {
    .navbar-brand {
        img {
            height: 46px;
        }
    }

    .link-suporte {
        cursor: pointer;
        color: $theme-sidenav-link;

        &:hover {
            color: $theme-sidenav-text;
        }
    }

    .navbar-nav {
        li {
            padding: 0 .25rem 0 .15rem;
            border-right: 1px solid rgb(91 91 91 / 50%);
            &:last-child {
                border: none;
            }
        }
    }
}

.titulo-tema {
    padding: 0.25rem 1.5rem;
    color: rgba(255, 255, 255, 0.5);
}
.selecionar-tema {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    .tema {
        padding: 0.25rem 1.5rem;
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        span {
            background-color: rgb(226 226 226 / 48%);
            color: #222;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;

            border-radius: 50px;
            padding: 2px;
            margin-right: 10px;
        }

        &.active {
            span {
                background-color: $color-success;
                color: #222;
            }
        }
    }
}

.w-30 {
    width: 30%;
}


/* Sidebar */

.sb-nav-fixed {
    #layoutSidenav {
        #layoutSidenav_nav {
            &.mostra-oportunidades {
                width: 270px;
                height: 100vh;
                .sb-sidenav-menu {
                    padding-bottom: 0;
                }

                ul {
                    min-height: 97%;
                }
                .title {
                    border-radius: 0;
                }
                .conteudo {
                    max-height: fit-content;
                    height: 85vh;
                }
                .total {
                    width: 100%;
                    background-color: #737373;
                    color: #fff;
                }
                .btn-open-sidebar {
                    display: none !important;
                }
            }
        }
        #layoutSidenav_content{
            &.mostra-oportunidades {
                padding-left: 260px;
                top: 40px;
                min-height: calc(100vh - 40px);
            }
        }
    }
}

.tag-ambiente-testes {
    background-color: #ffe000;
    color: #333;
    padding: 0.3rem .5rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.65rem;
    border-radius: 50px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: .5rem;
}

.loja-info {
    display: flex;
    align-items: center;
    .loja-label {
        font-style: italic;
        font-size: 0.7rem;
    }
    .loja-nome {
        font-weight: 500;
        font-size: 0.75rem;
    }
    .loja-link {
        font-size: 0.85rem;
        margin: 0.5rem 1rem;
    }
}

.sb-sidenav {
    .sb-sidenav-menu {
        .nav {
            .nav-link {
                padding-top: 0.3rem;
                padding-bottom: 0.3rem;
                font-size: 0.85rem;
                &.active {
                    border-left: 2px solid;
                    padding-left: 14px;
                    background: #3a3d42;
                }
                .sb-nav-link-icon {
                    width: 18px;
                }
            }
            .sb-sidenav-menu-heading {
                padding: 1.3rem 1rem 0rem;
            }
            .sb-sidenav-menu-nested {
                margin-left: 0;
            }
        }
    }
    .sb-sidenav-menu-nested {
        margin-left: 0;
        &.nav {
            .nav-link {
                padding-left: 42px;
                &.active {
                    padding-left: 40px;
                }
            }
        }
    }
}

/* Form */

.form-title-divider {
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: bold;
    margin-top: 1.5rem;
    margin-bottom: -10px;
}

.form-buttons {
    padding-top: 1.5rem;
    border-top: 1px solid #e5e5e5;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    display: flex;
    gap: .5rem;
}

.form-custom {
    border: none;
    padding: 0;

    .col-form-label,
    .form-control {
        font-size: 0.9rem;
    }
    /* Input */
    .form-control {
        border-radius: 0;
    }

    &--large {
        margin-bottom: 100px;

        .form-buttons {
            border: none;
            position: fixed;
            bottom: 0;
            background-color: #fff;
            width: 100%;
            box-shadow: 0 5px 10px 5px #ccc;
            margin: 0;
            padding: 1rem;
            right: 0;
            padding-left: 250px;
            z-index: 999;
        }
    }
}
.input-helper {
    position: absolute;
    right: 15px;
    top: 0;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.9rem;
}

.faixas-preco {
    background-color: #f5f7f8;
    padding: 0.5rem 1rem;
    margin: 1rem 0.25rem;
    border-radius: 10px;

    .form-check {
        margin-bottom: 0.25rem;
    }

    &.sem-faixa {
        background-color: transparent;
        padding: 0 0 0 1.8rem;
    }

    .custom-control-label::before {
        border-radius: 50%;
        top: 0.1rem;
        left: -1.25rem;
    }
}

form {
    .password-field {
        -webkit-text-security: disc;
    }
}

.custom-input {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #ccc;
    background-color: transparent !important;

    &:focus {
        box-shadow: none;
        border-bottom: 2px solid #999;
    }
}

/* Uploading file - loading */
.uploading-loading {
    width: 100%;
    height: 140px;
    background-color: #f7f6f6;
    overflow: hidden;

    display: none;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    position: absolute;
    z-index: 1;

    &.active {
        display: flex !important;
    }
}
.loading-circle {
    display: inline-block;
    width: 80px;
    height: 80px;

    position: absolute;
    width: 80px;
    height: 80px;
    display: flex;
    left: calc(50% - 80px);
    top: 15px;
}
.loading-circle div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #999;
    border-radius: 50%;
    animation: loading-circle 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #999 transparent transparent transparent;

    right: 50%;
    left: 50%;
}
.loading-circle div:nth-child(1) {
    animation-delay: -0.45s;
}
.loading-circle div:nth-child(2) {
    animation-delay: -0.3s;
}
.loading-circle div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes loading-circle {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Card - Form */
.custom-form {
    background-color: transparent;
}

.custom-form__card {
    @extend .card-shadow;

    .card-header {
        padding: 0.75rem 1rem;
        border-bottom: 1px solid rgb(230 230 230);

        h5 {
            margin-bottom: 0;
            text-transform: uppercase;
            font-size: 0.9rem;
            font-weight: 700;
        }
        .btn-veja-como-funciona {
            margin-bottom: 0;
        }
        .card-with-button {
            display: flex;
            align-items: center;
            justify-content: space-between;

            height: 34px;

            a,
            button {
                text-transform: none;
            }
        }
    }
    .card-body {
        padding: 1rem;
    }

    .card-list {
        border-bottom: 1px solid #f5f5f5;
        padding: 0.5rem 0;
        align-items: center;

        &:last-child {
            border-bottom: none;
        }
    }

    &--large {
        max-height: 170px;
        .card-body {
            overflow-y: auto;
        }
    }

    .card-footer {
        background-color: #fff;
        border-top: 1px solid rgb(0 0 0 / 5%);
    }
}

.custom-form__accordion {
    background-color: #fff;
    .card-header {
        padding: 0;
        background-color: #fbfbfb;

        h2 {
            display: flex;
        }
        button {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: flex-end;

            margin-bottom: 0;
            text-transform: uppercase;
            font-size: 0.9rem;
            font-weight: 700;
            color: #212529;
            padding: 0.5rem 1rem;

            svg {
                margin-right: 1rem;
                transition: all 0.5s ease;
                transform: rotate(360deg);
            }

            &:hover {
                text-decoration: none;
                opacity: 0.8;
            }
            &.collapsed {
                svg {
                    transform: rotate(270deg);
                }
            }
        }
    }

    .card-footer {
        background-color: #fff;
        border-top: 1px solid rgb(0 0 0 / 5%);
    }
}

/* Table */

.table-custom {
    @extend .card-shadow;

    background-color: #fff;

    td {
        padding: 0.5rem;
        vertical-align: middle;
        border-top: 1px solid #dee2e6;
        font-size: 0.85rem;

        // Acoes
        &:last-child {
            min-width: 100px;
        }
    }
    thead {
        background-color: #ffffff;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;

        th {
            font-size: 0.8rem;
            border: none;
        }
    }

    tbody {
        tr {
            border-left: 7px solid $color-success-200;
        }
    }

    /* Table - Row - Status */
    .table-row-inactive {
        border-left-color: #dc3545;
    }

    .table-row-no-status {
        border-left: none;
    }
    &.table-row-no-status {
        tbody {
            tr {
                border-left: none;
            }
        }
    }
}

.table-multi-card {
    .td-min-150 {
        min-width: 150px;
    }
    .td-min-300 {
        min-width: 300px;
    }
    .td-max {
        max-width: 400px;
    }
}

/* File - Image */
.img-block-logo {
    background-color: #f7f6f6;
    padding: 0.5rem;
    height: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;

    img {
        max-height: 100px;
    }

    .btn-danger {
        display: block;
        position: absolute;
        right: 2px;
        top: 2px;
        padding: 3px 6px 0 6px;
    }
}

/* Autocomplete */
.ui-menu {
    .ui-menu-item {
        font-size: 0.9rem;
        padding: 0.25rem 0.5rem;
        &.hover {
            background-color: rgba(0, 0, 0, 0.075);
            color: #333333;
            border-color: transparent;
        }
    }
    .lista-produto {
        padding: 0;

        .ui-menu-item-wrapper {
            &:last-child {
                display: none;
            }
        }
    }
    .ui-menu-item-wrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        border-color: transparent;
        &.ui-state-active {
            background-color: rgba(0, 0, 0, 0.075);
            color: #333333;
            border-color: transparent;
        }
    }
}

// Tooltip Ajuda
.ui-tooltip {
    &.ui-widget {
        background-color: #333;
        color: #fff;
        font-size: 0.85rem;
    }
}

// Pagination
.pagination--block {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.pagination {
    border-radius: 0.5rem;
    margin: 0 auto;
    text-align: center;
    left: 50%;
}
.pagination-text {
    position: absolute;
    left: 15px;
}
.page-item {
    .page-link {
        border-radius: 0.5rem;
        padding: 0.5rem 0.85rem;
        margin: 0.2rem;
        font-size: 1rem;
    }
    &.active {
        .page-link {
            background-color: #dee2e6;
            border-color: #dee2e665;
            color: #2a2c2f;
        }
    }
    &:last-child,
    &:first-child {
        .page-link {
            border-radius: 0.5rem;
        }
    }
}

// Card - Sem resultado

.box-empty-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100px;
    margin: 1rem;
    font-size: 1rem;
    color: #666;
}

// Manutencao

.alert-manutencao {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: #cce5ff;
    border-color: #b8daff;

    .info {
        color: #004085;
    }
}

.configurar-loja {
    iframe {
        height: 433px;
    }
}

// Notificacoes
.notificacoes-block {
    max-width: 500px;
    min-width: 420px;
}

.area-notitificacoes {
    .icon-circle {
        border-radius: 100px;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dropdown-item {
        white-space: normal;
    }
}

// Custom Badge

.custom-badge {
    &.badge {
        padding: 0.25rem 0.65rem;
        border-radius: 50px;
    }
    &.badge-primary {
        color: #194b97;
        background-color: rgb(217, 244, 253);
    }
    &.badge-secondary {
        color: #484b4e;
        background-color: #e5e6e8;
    }
    &.badge-success {
        color: #245324;
        background-color: #d4edd4;
    }
    &.badge-info {
        color: #125a66;
        background-color: #ceebef;
    }
    &.badge-danger {
        color: #c62c3a;
        background-color: #fce3e5;
    }
}

// Custom Tabs

.custom-tabs {
    display: flex;
    align-items: center;
    .nav-item {
        min-width: 120px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 0.85rem;
    }
    .nav-link {
        text-align: center;
        color: #808080;
        border: none;
        border-bottom: 3px solid transparent;
        &:hover {
            border: none;
            border-bottom: 3px solid transparent;
            color: $color-secondary;
        }
        &.active {
            background-color: transparent;
            color: $color-primary;
            border: none;
            border-bottom: 3px solid $color-primary;
        }
        .indicador-protocolo-aberto {
            background-color: #1e8e3e;
            width: 10px;
            height: 10px;
            border-radius: 10px;
            margin-left: 10px;
            border: 1px solid #fff;
        }
    }
}

.custom-sm-tabs {
    @extend .custom-tabs;

    .nav-item {
        min-width: auto;
    }
    .nav-link {
        background-color: transparent;
    }
}

// Card Selected
.icon-selected {
    display: none;
}
.card-custom-radio {
    border-radius: 0.65rem;
    padding: 1rem 1.5rem;
    border: 2px solid #dee2e6;
    box-shadow: none;
    margin-bottom: 1rem;

    .form-check {
        padding-left: 1.5rem;
    }
}
.card-selected {
    background-color: #e9f8e9;
    border: 2px solid $color-success;

    .custom-radio {
        div {
            color: $color-success-dark;
        }
    }
    .radio-checkmark {
        display: none;
    }

    // Checkmark
    .icon-selected {
        display: flex;
        svg {
            color: $color-success-dark;
            width: 20px;
            height: 20px;
            position: absolute;
            left: -30px;
            top: 0;
        }
    }
}

/* Custom Radio Button Card */
.custom-radio {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked ~ .radio-checkmark {
            display: none;
        }
    }
}
.radio-checkmark {
    position: absolute;
    top: 0;
    left: -30px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid #dee2e6;
}

// Contador
.label-contador {
    position: absolute;
    font-size: 0.8rem;
    top: 5px;
    right: 15px;
}

// Criar assinatura
.criar-assinatura {
    padding-bottom: 2rem;
    .enderecos {
        .selecionar-endereco {
            input {
                display: none;
            }
        }
    }
}

// Validação de Senha Forte
.strong-pass {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 0.5rem;
    position: relative;

    p {
        font-size: 0.76rem;
        font-weight: 400;
        margin-bottom: 0.5rem;
        line-height: 1.25;
        svg {
            width: 11px;
            height: 10px;
            display: inline-block;
            margin-right: 2px;
        }
    }
}
.password-status {
    margin: 0;
    margin-top: 0.35rem;
    .status {
        padding-top: 5px;
    }
}

.show-password {
    position: absolute;
    right: 20px;
    top: 32px;
}

.input-strong-pass {
    position: relative;
    .show-password {
        top: 40px;
    }
}

// FAQ
.faq {
    .faq-group {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--name {
            font-weight: bold;
        }
    }
    .faq-question {
        .list-group-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-left: 5px solid green;
            &.inactive {
                border-left-color: var(--danger);
            }
        }
    }
    .list-group {
        border-radius: 0;
    }
    .card-body {
        padding: 0;
    }
}

// Breadcrumbs

.breadcrumb-item+.breadcrumb-item::before {
    content: '>';
}

.custom-breadcrumbs {
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: .85rem;
}

// Relatorio DataStudio
.datastudio-block {
    background-color: #eee;
    position: relative;
    padding-bottom: 75%; /* Proporcao: 900 x 1200 */
    height: 0;
    overflow: hidden;
}
.datastudio-block iframe,
.datastudio-block object,
.datastudio-block embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.datastudio-report-eloca {
    @extend .datastudio-block;
    position: relative;
}

// Checklist - Start
.card__checklist {
    padding-left: 0;
    list-style: none;

    svg {
        border-radius: 50px;
        width: 26px;
        height: 26px;
        padding: 6px;
        margin-right: 10px;

        color: transparent;
        border: 2px solid $color-tertiary;
    }

    .check-item {
        margin-bottom: 0.5rem;
        display: flex;
        a {
            color: $color-tertiary;
            &:hover {
                opacity: 0.8;
            }
        }
        &.done {
            text-decoration: line-through;
            color: #808080;
            a {
                color: #808080;
            }
            svg {
                background-color: $color-success;
                color: #fff;
                border-color: $color-success;
            }
        }
    }
}


// Lp pelo backoffice
.landing-page {
    .card-bloco {
        background-color: #fbfbfb;
        margin: 1rem 0.25rem;
        border-radius: 8px;
        border: none;
    }
    .color-block {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.25rem;
        span {
            display: inline-block;
            border: 1px solid #ccc;
            border-radius: 50px;
            width: 20px;
            height: 20px;
        }
    }
    .no-content {
        font-style: italic;
        color: #6c757d;
    }

    .bloco-inativo {
        opacity: 0.5;
        &:before {
            color: #989898;
            background-color: #d2d2d282;

            content: "BLOCO INATIVO";
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 2rem;
            opacity: 1;
        }
    }
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    background-color: #eeeeee;
    padding-top: 30px;
    height: 0;
    overflow: hidden;

    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

// toast

.toast {
    border-radius: 0;
    border: none;
    background-color: rgb(255 255 255 / 95%);

    &__default {
        opacity: 1;
    }

    .toast-body {
        border-left: 4px solid transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 300px;
        max-width: 350px;
        .message {
            color: #666666;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                font-weight: bold;
                color: #333333;
            }
        }
        p {
            color: #333333;
        }
    }

    &__success {
        border-left: 4px solid $color-success;
        background-color: #e9ffeb;
        svg {
            color: $color-success;
        }
    }
    &__danger {
        border-left: 4px solid $color-error;
        background-color: #fae1de;
        svg {
            color: $color-error;
        }
    }
    &__warning {
        border-left: 4px solid $color-warning;
        background-color: #fff3cd;
        svg {
            color: $color-warning;
        }
    }
    &__info {
        border-left: 4px solid $color-info;
        background-color: #d1ecf1;
        svg {
            color: $color-info;
        }
    }
}


.popover {
    transform: scale(1);
    opacity: 1;
    background-color: #fff;
    border-radius: 1rem;
    border: none;
    box-shadow: 0 1px 0.5px rgba(11,20,26,.13);
    .arrow {
        &::before{
            border-right-color: #fff;
        }
        &::after {
            border-left-color: #fff;
        }
    }
    .popover-body {
        padding: 0.5rem;
    }
}

.lista-reacoes {
    max-height: 180px;
    overflow-y: scroll;
}

.modal-body-heigth {
    max-height: 60vh;
}

.scroll-maxheigth-75 {
    overflow-y: auto;
    max-height: 75vh;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .form-custom--large {
        .form-buttons {
            padding-left: 15px;
            left: 0;
            text-align: center;
        }
    }
    .sb-topnav {
        .navbar-brand {
            width: 160px;
        }
        .navbar-nav {
            li {
                padding: 0;
                border-right: none;
            }
        }
    }
    .sb-sidenav {
        .sb-sidenav-menu {
            .nav {
                .nav-link {
                    padding-top: 0.65rem;
                }
            }
        }
    }
    .sb-nav-fixed {
        &.sb-sidenav-toggled {
            #layoutSidenav {
                #layoutSidenav_nav {
                    &.mostra-oportunidades {
                        width: 277px;
                    }
                }
            }
        }
        #layoutSidenav {
            #layoutSidenav_nav {
                &.mostra-oportunidades {
                    width: 220px;
                }
            }
            #layoutSidenav_content {
                &.mostra-oportunidades {
                    padding-left: 220px;
                }
            }
        }

    }

    .loja-info {
        display: none;
    }
    .notificacoes-block {
        left: -120px;
        right: -120px;
        max-width: 300px;
        min-width: 300px;
    }
    .pagination--block {
        flex-direction: column-reverse;
        margin-top: 0;
    }
    .pagination-text {
        position: relative;
        margin-top: 1rem;
        text-align: center;
    }
    .page-number {
        display: none;
    }

    .toast {
        .toast-body {
            max-width: 280px;
        }
    }

    .faixas-preco {
        padding: 0.5rem 1rem;
        margin: 0 0 1rem 0;
        .form-group {
            margin-bottom: 0.5rem;
        }
        .form-check {
            margin-bottom: 1rem;
        }
    }


    .custom-form__card {
        .card-header {
            align-items: flex-start;
            .btn {
                text-align: left;
            }
            .dropleft .dropdown-toggle {
                text-align: center;
            }
            .card-with-button {
                flex-direction: column;
                height: auto;
                align-items: flex-start;
                text-align: center;
            }
        }

        .btn-card-status {
            width: 100%;
            margin-bottom: 0.25rem;
        }
        .btn-group {
            display: block;
            width: 100%;
        }
    }
    .alert-manutencao {
        flex-direction: column;
    }
    .manutencao-buttons {
        margin-top: 1rem;
    }

    .configurar-loja {
        iframe {
            height: 200px;
        }
    }
    .strong-pass {
        flex-direction: column;
        p {
            margin-bottom: 0.25rem;
        }
    }
    .faq {
        .faq-question {
            .list-group-item {
                display: flex;
                flex-direction: column;
                align-items: stretch;
            }
        }
        .faq-buttons {
            margin-top: 0.5rem;
            text-align: right;
            .modal-body {
                text-align: left;
            }
        }
    }
    .card-aligned {
        .card-body {
            .card-content {
                flex-direction: column-reverse;
            }
            .custom-control-label {
                margin-top: 2rem;
            }
        }
    }
    .custom-tabs {
        flex-wrap: nowrap;
        scroll-behavior: smooth;

        overflow-y: hidden;
        overflow-x: scroll;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;

        .nav-item {
            min-width: auto;
        }
        .nav-link {
            padding: 0.5rem 1rem;
            white-space: nowrap;
        }
    }
    .card__buttons {
        flex-direction: column;
        .btn {
            width: auto;
        }
    }
}
