/* import SB Admin */
@import "sb-admin/styles.scss";

@import "variables.scss";
@import "themes.scss";
@import "components.scss";
@import "buttons.scss";
@import "wizard.scss";
@import "pages.scss";
@import "custom.scss";
@import "flags.scss";

@import "../site/templates/1/lp/planos";
