.eloca-theme-padrao {
    color-scheme: normal;
    .sb-topnav,
    #layoutSidenav_nav,
    .sb-sidenav {
        color: $theme-sidenav-text;
        background-color: $theme-sidenav-bg;

        a,
        button {
            color: $theme-sidenav-link;
            &:hover {
                color: $theme-sidenav-text;
            }
            &.active {
                color: $theme-sidenav-text;
            }
        }

        &.mostra-oportunidades {
            .sb-sidenav {
                background-color: $theme-sidenav-text;
            }
            .btn {
                color: #333;
                &:hover {
                    background-color: #ffffff;
                }
            }

            .lista-oportunidades {
                .oportunidade {
                    background-color: $theme-sidenav-text;
                    &.oportunidade-sem-dono {
                        background-color: #f8d3d8;
                    }
                    &.oportunidade-aberta {
                        background-color: #eafff8 !important;
                    }
                }
                a {
                    color: $theme-sidenav-bg;
                }
            }

        }
    }
    .sb-topnav {
        .logo-eloca,
        .loja-nome {
            color: #fff;
        }
    }

    #layoutSidenav_content {
        background-color: #f3f4f5;
    }

    .nav-item {
        .dropdown-menu-right {
            color: $theme-sidenav-text;
            background-color: $theme-dark-bg-200;

            a {
                &:hover,
                &:focus {
                    color: $theme-sidenav-text;
                    background-color: $theme-sidenav-bg;
                }
            }

            .dropdown-divider {
                border-color: $theme-dark-bg-400;
            }
        }
    }
}

// Dark
.eloca-theme-dark {
    color-scheme: dark;
    background-color: $theme-dark-bg-500;
    color: #ddd;

    .sb-topnav {
        color: $theme-dark-sidenav-text;
        background-color: $theme-dark-sidenav-bg;
        box-shadow: 0px 1px 2px #0d0d0d;

        a,
        button,
        .link-suporte {
            color: $theme-dark-sidenav-link;

            &:hover {
                color: $theme-dark-sidenav-text;
            }
            &.active {
                color: $theme-dark-sidenav-text;
            }
        }
        .logo-eloca,
        .loja-nome {
            color: #fff;
        }

    }
    #layoutSidenav_nav,
    .sb-sidenav {
        color: $theme-dark-sidenav-text;
        background-color: $theme-dark-sidenav-bg;

        a,
        button {
            color: $theme-dark-sidenav-link;

            &:hover {
                color: $theme-dark-sidenav-text;
            }
            &.active {
                color: $theme-dark-sidenav-text;
            }
        }
        #minhas-oportunidades {
            a {
                color: $theme-dark-sidenav-text;

                &:hover {
                    color: $theme-dark-sidenav-text;
                }
                &.active {
                    color: $theme-dark-sidenav-text;
                }
            }
        }

        .oportunidade-sem-dono {
            a {
                color: #fff !important;
            }
        }

        .oportunidade-aberta {
            background-color: #3d4f45 !important;
        }

    }

    #layoutSidenav_content {
        background-color: $theme-dark-bg-500;
    }

    .btn-open-sidebar {
        background-color: #3a3d42;
    }

    .uploading-loading {
        background-color: #444444;
    }

    a,
    .btn-link {
        color: $theme-dark-link;
        &:hover {
            color: $theme-dark-sidenav-text;
        }
    }
    .btn-outline-primary,
    .btn-outline-secondary,
    .btn-outline-success {
        background-color: transparent;
    }
    .btn-card-status {
        color: $theme-dark-link;
    }

    .support-link {
        color: $theme-dark-link;
    }

    .p-color {
        color: $theme-dark-primary;
    }
    .p-bg {
        background-color: $theme-dark-primary;
    }
    .d-color {
        color: #474a4d;
    }
    hr {
        border-color: #474a4d;
    }
    .melhorenvio-path {
        fill: #fff;
    }

    .alert-devolucao {
        background-color: #e8198e46;
        color: #ddd;
        .close {
            text-shadow: none;
        }
    }

    .btn-light {
        background: $theme-dark-bg-200;
        color: $theme-dark-link;
        border-color: $theme-dark-bg-400;

        &:hover {
            color: #fff;
        }
    }

    .link {
        .small {
            color: $theme-dark-text;
        }
    }

    .ui-widget {
        &.ui-widget-content {
            border-color: $theme-dark-bg-300;
            background-color: $theme-dark-bg-300;
        }
    }

    .ui-widget-content {
        background: $theme-dark-bg-400;
        color: #ccc;

        a {
            color: #ccc;
            background: $theme-dark-bg-300;
            border-color: $theme-dark-bg-300;
        }
    }

    .ui-widget-header {
        background: $theme-dark-bg;
        border-color: $theme-dark-bg;
        color: $theme-dark-text;
    }

    .btn-cancel,
    .btn-see-product,
    .btn-log {
        background: $theme-dark-bg-hover;
        border-color: $theme-dark-bg-300;
        color: #cccccc;
    }
    .btn-primary,
    .btn-success,
    .btn-secondary,
    .btn-danger {
        color: #fff;
    }
    .btn-success {
        background-color: $theme-dark-success;
        border-color: $theme-dark-success-dark;
        &:hover {
            background-color: $theme-dark-success-dark;
        }
    }
    .btn-primary {
        background-color: $theme-dark-primary;
        border-color: $theme-dark-primary-dark;
    }
    .btn-outline-primary {
        border-color: $theme-dark-link;
        color: $theme-dark-link;
        &:hover {
            color: #fff;
            background: $theme-dark-primary;
        }
    }
    .btn-outline-secondary {
        border-color: $theme-dark-bg-400;
        color: $theme-dark-text;
    }
    .btn-outline-danger {
        border-color: $theme-dark-danger-light;
        color: $theme-dark-danger-light;
        &:hover {
            background-color: $theme-dark-danger;
            color: #fff;
        }
    }
    .btn-outline-success {
        border-color: $theme-dark-success-light;
        color: $theme-dark-success-light;

        &:hover {
            background-color: $theme-dark-success;
            color: #fff;
        }
    }

    .btn-delete {
        color: #fff;
    }

    .toast {
        .close {
            color: #43464a;
        }
    }

    .manutencao-buttons {
        .btn-outline-secondary {
            color: $theme-dark-bg-500;
        }
    }
    .alert {
        .btn-outline-secondary {
            color: $theme-dark-bg-500;
        }
    }

    .img-block-logo {
        background-color: $theme-dark-bg-300;
    }
    .item__details {
        .no-image {
            background-color: transparent;
        }
    }

    .text-danger,
    .invalid-feedback {
        color: $theme-dark-danger-light !important;
    }

    .text-muted {
        color: #8d9398 !important;
    }
    .bg-yellow {
        background-color: hsl(53deg 80% 50%) !important;
    }
    .bg-warning {
        a,
        svg {
            color: $theme-dark-bg-100 !important;
        }
    }

    .btn-outline-warning {
        border-color: #fbbe08;
    }

    .btn-dropdown-telefone {
        background-color: transparent;
        border-color: $theme-dark-bg-600;
        color: #fff;

        &:hover {
            background-color: $theme-dark-bg-700;
        }
    }

    .icon-divider {
        color: #666;
    }
    .dropdown-item {
        &:hover {
            background-color: $theme-dark-bg-500;
        }
    }

    .input-group-prepend {
        .dropdown-item {
            color: #fff;

            &:hover {
                color: #444444;
            }
        }
    }
    .input-group-text {
        background-color: #444444;
        border-color: #444444;
        color: #ddd;
    }

    .form-control {
        &::placeholder {
            color: #666;
        }
    }

    .custom-calendar {
        .weekdays {
            background-color: #2b2e32;
        }
        .days {
            background-color: #2b2e32;
            li {
                border-color: #121212;
            }
        }
    }

    .nav-item {
        .dropdown-menu-right {
            color: $theme-sidenav-text;
            background-color: $theme-sidenav-bg;

            a {
                &:hover,
                &:focus {
                    color: $theme-sidenav-text;
                    background-color: $theme-sidenav-bg;
                }
            }

            .dropdown-divider {
                border-color: $theme-dark-bg-400;
            }
        }
    }
    .dropdown-menu {
        background-color: $theme-dark-bg-400;
        a {
            &:hover {
                background-color: $theme-dark-bg-400;
            }
        }
    }
    .btn-acoes {
        .dropdown-item {
            color: $theme-dark-sidenav-link;

            &:hover {
                background-color:$theme-dark-text !important;
                color: $theme-dark-bg-100 !important;
            }
        }
    }
    .custom-control-label {
        &::before {
            background-color: $theme-dark-bg-100;
        }
    }

    .faixas-preco {
        background-color: $theme-dark-bg-300;
    }

    .semantic {

        .ui.label {
            color: $theme-dark-text;
            background-color: $theme-dark-bg;
        }
        .ui.dropdown .menu {
            color: $theme-dark-text;
            background-color: $theme-dark-bg-300;

            .item {
                color: $theme-dark-text;
                border-color: $theme-dark-bg-600;
            }
        }
        .ui.selection.dropdown {
            background-color: $theme-dark-bg-300;
        }
        .ui.selection.active.dropdown .menu {
            border-color: $theme-dark-bg-300;
        }
    }

    .custom-badge {
        &.badge-info {
            color: $theme-dark-bg-100;
            background-color: #00dbff;
        }
        &.badge-danger {
            color: #ebf0f4;
            background-color: $theme-dark-danger-light;
        }
        &.badge-success {
            color: $theme-dark-bg-100;
            background-color: $color-success;
        }
        &.badge-secondary {
            color: #ebf0f4;
            background-color: #e5e6e83b;
        }
    }

    .nav-pills {
        &.nav-pills-subitems {
            background-color: $theme-dark-bg-100;
            color: #ccc;
            .nav-link {
                color: #ccc;
                background-color: $theme-dark-bg-100;
                border-color: $theme-dark-bg-100;
                &.active {
                    background-color: $theme-dark-bg-hover;
                    color: #ccc;
                    border-left-color: #ddd;
                }
            }
        }
    }

    .card {
        background-color: $theme-dark-bg-100;
        border-color: $theme-dark-bg-300;
        box-shadow: 3px 3px 10px #000000;

        .card-header {
            background-color: $theme-dark-bg-200;
        }
        .list-group-item {
            background-color: transparent;
            border-bottom: 1px solid rgb(255 255 255 / 13%);
        }
    }
    .card-store {
        .card-footer {
            background-color: $theme-dark-bg-300;
            a {
                color: $theme-dark-link;
            }
        }
    }

    .card__history {
        .note {
            border-color: #e2ecf62b;
        }
    }

    .card-custom-radio,
    .radio-checkmark {
        border-color: #505256;
    }
    .form-wizard {
        .icon-option {
            color: #ccc;
        }
    }

    .client__score {
        .score__value {
            background-color: $theme-dark-bg-100;
        }
    }

    .pagina-ajuda {
        .contract__list {
            border-color: $theme-dark-bg-200;
            .title {
                background-color: $theme-dark-bg-200;
            }
            .info {
                border-color: $theme-dark-bg-200;
            }
        }
        .thumb-border {
            border-color: $theme-dark-bg-300;
        }
        .blocks {
            .nav-link {
                background-color: $theme-dark-sidenav-bg;
            }
        }
    }

    .card-selected {
        background-color: $theme-dark-bg-200;
        border-color: $color-success;
        .icon-option {
            color: $color-success;
        }
    }

    .dashboard-status {
        a {
            color: #ccc;
        }
        hr {
            border-color: $theme-dark-bg-300;
        }
    }

    .dashboard-orders {
        .order-header {
            background-color: transparent;
        }
        .order-card {
            border-color: $theme-dark-bg-300;
        }
    }

    .assinatura-status,
    .order-status {
        hr {
            border-color: $theme-dark-bg-200;
        }
        &--6 {
            background-color: #77b5f420;
            color: #4fa7ff;
        }
        &--3,
        &--4 {
            color: #cccccc;
        }
    }
    .order-status {
        &--1 {
            color: #beb8b8;
        }
        &--4 {
            background-color: #77b5f420;
            color: #4fa7ff;
        }
        &--6 {
            background-color: $color-status-ativa-bg;
            color: $color-status-ativa;
        }
        &.payment-status--1 {
            color: #beb8b8;
        }
    }

    .order-item {
        border-color: $theme-dark-bg-200;
    }

    .assinatura-temporaria {
        .table-products {
            thead {
                th {
                    background-color: $theme-dark-bg-200;
                }
            }
            .input-quantidade {
                .input-group-text,
                input {
                    background-color: $theme-dark-bg-200;
                    border-color: $theme-dark-bg-200;
                }
            }
            .bg-gray {
                background-color: #555555;
                color: white;
            }
            .row-valores {
                background-color: $theme-dark-bg-200;
            }
        }
    }

    .table {
        color: #ddd;
    }
    .table-custom {
        border-color: $theme-dark-bg-100;
        background-color: $theme-dark-bg-hover;
        box-shadow: 3px 3px 10px #000000;
        color: #ddd;

        td,
        th {
            border-color: #222;
        }
        thead {
            background-color: $theme-dark-bg-hover;
            th {
                border-color: $theme-dark-bg-500;
            }
        }
        tr {
            &:hover {
                color: #ccc;
            }
        }
    }
    .custom-form {
        background-color: transparent;
    }

    .form-control,
    .custom-select,
    .custom-file-label {
        background-color: $theme-dark-bg-300;
        color: #ddd;
        border-color: $theme-dark-bg-600;
    }
    .custom-file-label {
        &::after {
            background-color: #444444;
            border-color: #444444;
            color: #ddd;
        }
    }
    .custom-form__accordion {
        background-color: $theme-dark-bg-200;
        .card-header {
            button {
                color: #ccc;
            }
        }
        .card-footer {
            background-color: $theme-dark-bg-200;
        }
    }

    .custom-form__card {
        .card-header {
            border-color: $theme-dark-bg-200;
        }
        .card-list {
            border-color: $theme-dark-bg-600;
        }
        .card-footer {
            border-color: $theme-dark-bg-200;
            background-color: transparent;
        }
    }
    .page-item {
        &.active {
            .page-link {
                background-color: $theme-dark-bg-400;
                color: #ddd;
            }
        }
        .page-link {
            border: none;
            background-color: $theme-sidenav-bg;
        }
    }
    .form-buttons {
        border-color: $theme-dark-bg-100;
    }
    .form-custom--large .form-buttons {
        background-color: $theme-dark-bg-100;
        box-shadow: 0 5px 10px 5px $theme-dark-bg-600;
    }

    .custom-tabs {
        border-bottom-color: $theme-dark-bg;
        background-color: $theme-dark-bg-500;
        .nav-link {
            &:hover {
                color: $theme-dark-text1;
            }
            &.active {
                color: $theme-dark-link;
                border-bottom-color: $theme-dark-link;

                background-color: $theme-dark-bg-500;
            }
        }
    }

    .table__cs {
        .loja {
            background-color: $theme-dark-bg-400;
        }
        .table-custom {
            thead {
                background-color: $theme-dark-bg-300;
            }
        }
    }

    .ck.ck-toolbar {
        background-color: $theme-dark-bg-300;
        color: #ccc;
        border-color: $theme-dark-bg-300;
    }
    .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
        border-color: $theme-dark-bg-300;
    }
    .ck.ck-reset_all,
    .ck.ck-reset_all * {
        color: #999;
    }
    .ck.ck-toolbar .ck.ck-toolbar__separator {
        background-color: $theme-dark-bg-300;
    }

    .ck.ck-editor__main > .ck-editor__editable {
        background-color: $theme-dark-bg-300;
    }
    .ck-source-editing-area {
        textarea {
            background-color: $theme-dark-bg-300;
            color: #999;
        }
    }
    .ck-widget.raw-html-embed .raw-html-embed__content-wrapper {
        background-color: $theme-dark-bg-300;
    }
    .ck-widget.raw-html-embed .raw-html-embed__source {
        color: #333333;
    }
    .ck-widget.raw-html-embed .raw-html-embed__source[disabled] {
        background-color: $theme-dark-bg-300;
    }
    .client__header .client__photo,
    .client__header .client__icon {
        border-color: #222;
        background-color: $theme-dark-bg-300;
    }
    .ck.ck-button:not(.ck-disabled):hover,
    a.ck.ck-button:not(.ck-disabled):hover,
    .ck.ck-splitbutton.ck-splitbutton_open
        > .ck-button:not(.ck-on):not(.ck-disabled):not(:hover),
    .ck.ck-splitbutton:hover
        > .ck-button:not(.ck-on):not(.ck-disabled):not(:hover) {
        background-color: $theme-dark-bg-hover;
    }

    .modal-content {
        background-color: $theme-dark-bg-hover;
        color: $theme-dark-text;
        .modal-header,
        .modal-footer {
            border-color: $theme-dark-bg;
        }
    }
    .close {
        color: $theme-dark-text;
    }
    .alert-warning {
        .close {
            color: #856404;
        }
        a {
            &:hover {
                color: #856404;
            }
        }
    }

    .ui-menu {
        .ui-menu-item-wrapper {
            &.ui-state-active {
                background-color: rgb(194 194 194 / 18%);
                color: #cccccc;
            }
        }
    }


    .nota-pedido,
    .nota-oportunidade {
        background-color: #fff;
        color: #333;
    }

    // Lp pelo backoffice
    .landing-page {
        .card {
            background-color: transparent;
            box-shadow: none;
        }
        .card-bloco {
            background-color: $theme-dark-bg-700;
            box-shadow: none;
        }
    }

    /* Planos Eloca */
    .lp-planos__pagina {
        .planos {
            .nome {
                background-color: $theme-dark-bg-700;
            }
            .plano {
                background-color: $theme-dark-bg-700;
            }
        }
        .features-row {
            background-color: $theme-dark-bg-500;
            .feature {
                background-color: $theme-dark-bg-100;
                border-color: $theme-dark-bg-500;

                &.empty {
                    background-color: $theme-dark-bg-500;
                }
            }
            &.features-subtitle {
                .feature {
                    background-color: $theme-dark-bg-500;
                }
            }
        }
        .accordion {
            .card-header {
                button {
                    color: #ddd;
                    background-color: $theme-dark-bg-700;
                }
            }
        }
        .card-taxas {
            background-color: $theme-dark-bg-100;
        }
    }

    .apexcharts-legend-text {
        color: #fff !important;
    }
    .apexcharts-title-text {
        fill: #fff !important;
    }
    .apexcharts-annotations {
        fill: #fff !important;
    }
    .apexcharts-yaxis {
        fill: #fff !important;
    }
    .apexcharts-text,
    .apexcharts-subtitle-text {
        fill: #fff !important;
    }
    .apexcharts-tooltip {
        background: $theme-dark-bg-500 !important;
    }
    .apexcharts-tooltip-title {
        background: $theme-dark-bg-500 !important;
    }

    .progress {
        background-color: $theme-dark-bg-500;
    }

    .mensagem {
        a,
        .btn-link {
            color: $theme-dark-link;
            &:hover {
                color: $theme-dark-primary;
            }
        }
    }
    .ajuda-rodape {
        background-color: $theme-dark-bg-200;
    }
    .ajuda-sidebar {
        .nav-link {
            color: $theme-dark-sidenav-text;

            &.active,
            &:hover {
                background-color: #222529;
                color: #fff;
            }
        }
        .grupo {
            border-bottom-color: $theme-dark-bg-200;
        }
    }
    .ajuda {
        .bloco {
            &:hover {
                box-shadow: 3px 3px 2px #000000;
            }
        }
        .bloco-imagem {
            background-color: $theme-dark-bg-200;
        }
    }
}

// Light
.eloca-theme-light {
    color-scheme: light;
    .sb-topnav,
    #layoutSidenav_nav,
    .sb-sidenav {
        color: $theme-light-sidenav-text;
        background-color: $theme-light-sidenav-bg;

        a,
        button,
        .link-suporte {
            color: $theme-light-sidenav-link;

            &:hover {
                color: $theme-light-sidenav-text;
            }
        }
    }

    #layoutSidenav_content {
        &:before {
            background: #fff !important;
            opacity: 0.8 !important;
        }
    }

    #layoutSidenav_content {
        background-color: #f3f4f5;
    }
    .sb-topnav {
        box-shadow: 1px 1px 12px #e8e8e8;
        .dropdown-item {
            &:active {
                background-color: $theme-light-sidenav-bg;
            }
        }
        .navbar-nav {
            li {
                border-color: rgb(238 238 238);
            }
        }
    }
    .search-input {
        background-color: #fff;
        border-color: #ced4da;
        &:focus {
            background-color: $theme-light-sidenav-bg;
            color: $theme-light-sidenav-text;
            border-color: #7f7f7f;
        }
    }

    .btn-open-sidebar {
        background-color: #ffffff;
        color: #666;
        box-shadow: 3px 3px 10px #bababa;
    }

    .sb-sidenav {
        box-shadow: 3px 3px 10px #efefef;
        a {
            &.active {
                color: $theme-light-sidenav-text;
                background-color: #e6e6e6 !important;
            }
        }
        .loja-nome {
            color: #333;
        }
    }
    .selecionar-tema {
        .tema {
            span {
                background-color: #ccc;
                color: #f7f7f7;
            }
            &.active {
                span {
                    color: #f7f7f7;
                }
            }
        }
    }
    .titulo-tema {
        color: $theme-light-sidenav-text;
    }
}
