body {
    font-family: 'IBM Plex Sans', -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
}

.search-input {
    background-color: #212529;
    color: #ffffff;
    border: 1px solid #343a40;
}

.search-input::placeholder {
    color: #6c757d;
}

.search-input:focus {
    background-color: #212529;
    color: #ffffff;
    border-color: #495057;
    box-shadow: none;
}

.small-90 {
    font-size: 90%;
    font-weight: 400;
}

.bg-main,
.btn-main {
    background-color: $color-primary;
}

.shadow-lg {
    appearance: none;
    -webkit-appearance: none;
    -webkit-box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%) !important;
    box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%) !important;
}

.sb-topnav {
    box-shadow: 0px 1px 2px #0d0d0d;
    .logo-eloca {
        svg {
            max-width: 120px;
        }
    }
}
.btn-open-sidebar {
    position: absolute;
    left: 210px;
    top: 60px;
    background: #212529;
    border-radius: 200px;
    color: #ffffff;
    width: 32px;
    height: 32px;
    transition: all ease-in 0.5;
    z-index: 1;
}

.btn-open-sidebar-chat {
    position: absolute;
    float: right;
    right: 30px;
    top: 10px;
    background: #a1a2a3;
    border-radius: 200px;
    color: #ffffff;
    width: 32px;
    height: 32px;
    transition: all ease-in 0.5;
    z-index: 1;
}
.sb-sidenav-toggled {
    .btn-open-sidebar {
        left: 228px;
        svg {
            rotate: 180deg;
        }
    }
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 14px solid #666666;
}

.tempo-fila {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.bloco-tempo-medio {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 8px;
    margin-top: 1rem;
    margin-bottom: .5rem;
}

/* === General === */

@media print {
    .container-fluid {
        padding: 0;
    }
    .sb-nav-fixed #layoutSidenav #layoutSidenav_content {
        width: 100% !important;
        padding-left: 0;
        margin-left: 0;
        top: 0;
        background-color: transparent;
    }
    .sb-nav-fixed #layoutSidenav #layoutSidenav_nav {
        width: 0 !important;
    }
    .btn-open-sidebar {
        display: none !important;
    }
    .overlayer-full {
        display: none;
    }
    .sb-sidenav-toggled #layoutSidenav #layoutSidenav_content:before {
        opacity: 0;
    }
    .card-body {
        height: auto !important;
        overflow: visible !important;
    }
    .custom-tabs {
        .nav-item {
            min-width: auto;
        }
    }
    .nav-link {
        display: none;
        min-width: auto;
        &.active {
            display: block;
            float: left;
            min-width: 120px;
        }
    }
}

/* Eloca */
.eloca-color {
    color: $color-eloca;
}

/* Primary */
.p-color {
    color: $color-primary;
}
.p-bg {
    background-color: $color-primary !important;
    color: $color-light;
}
.p-border {
    border-left: 4px solid $color-primary !important;
}
.pd-bg {
    background-color: $color-primary-darker;
    color: $color-light;
}

/* Secondary */
.s-color {
    color: $color-secondary;
}
.s-bg {
    background-color: $color-secondary;
    color: $color-light;
}
.s-border {
    border: 4px solid $color-secondary;
}
/* Tertiary */
.t-color {
    color: $color-tertiary;
}
.t-border {
    border-left: 4px solid $color-tertiary !important;
}
.t-bg {
    background-color: $color-tertiary !important;
    color: $color-light;
}
/* Accent */
.a-color {
    color: $color-accent;
}
.a-bg {
    background-color: $color-accent !important;
    color: $color-light;
}
.a-border {
    border-left: 4px solid $color-accent !important;
}
.a-color-dark {
    color: #34495e;
}
/* Complementary */
.c-color {
    color: $color-complementary;
}
.c-bg {
    background-color: $color-complementary !important;
    color: $color-light;
}
.c-border {
    border-left: 4px solid $color-complementary !important;
}

/* Marcas */
.whatsapp-color {
    color: $color-whatsapp;
}

.l-color {
    color: #b8b8b8;
}
.muted-border {
    border-left: 4px solid #6c757d !important;
}
.xs-text {
    font-size: 0.7rem;
}
.md-text {
    font-size: 1rem;
}
.lg-text {
    font-size: 1.5rem;
}
.b-text {
    font-weight: bold;
}

.text-line-through {
    text-decoration: line-through;
}

.gap-8 {
    gap: 8px;
}
.gap-16 {
    gap: 16px;
}

/* Elements */

a {
    color: $color-primary-light;
    &:hover {
        text-decoration: none;
    }
    &.link {

        .small{
            color: $color-dark;
        }
    }
}
.btn-link {
    &:hover {
        text-decoration: none;
    }
}

h1 {
    font-size: 1.5rem;
}

input[type="color"] {
    cursor: pointer;
}

.input-group-text {
    min-width: 44px;
    height: 38px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.input-group-prepend {
    input[type="color"] {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: 50px;
        padding: 2px 5px;
    }
    img.bandeira {
        max-width: 20px;
        margin-right: 0.5rem;
        margin-left: 0.25rem;
    }
    .btn.dropdown-toggle:focus {
        box-shadow: none;
    }
    .dropdown-item {
        cursor: pointer;
    }
}
.dropdown-item {
    border-radius: 0;
    font-weight: 500;
    padding: 0.25rem 1.5rem;
}
.bandeira {
    width: 20px;
}
// bandeiras faltantes no semantic
i.flag.ss:before,
i.flag.ac:before,
i.flag.xk:before {
    background-position: -36px -1482px !important;
}

.semantic {
    .padrao::before {
        background-color: #b6b5b5 !important;
    }
    .dropdown {
        height: auto;
        min-height: 38px;

    }
    .ui.multiple.dropdown > .label {
        font-size: .8rem;
        font-weight: 500;
    }
    .ui.multiple.search.dropdown>input.search {
        margin: .1rem;
    }
    .ui.multiple.search.dropdown>.text {
        font-size: .9rem;
    }
    .ui.label>.delete.icon {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-image: url("../../img/painel/icon-times.svg");
        background-repeat: no-repeat;
    }
    .ui.selection.dropdown {
        min-height: 38px;
        border-color: #ced4da;
    }
    .ui.fluid.dropdown>.dropdown.icon {
        padding:.5rem .85rem;
    }
}

.btn-dropdown-telefone {
    background-color: transparent;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-color: #ced4da;
    display: flex;
    align-items: center;
    padding: 6px;

    &:hover {
        background-color: #e9ecef;
    }
}

.sm-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
        padding-right: 0.25rem;
        padding-left: 0.25rem;
    }
}

.alert-devolucao {
    background-color: $color-status-devolucao-bg;
    color: $color-status-devolucao;

    .alert-link {
        color: $color-status-devolucao;
    }
}

.melhorenvio-path {
    fill: #00519d;
}
/* Listagens */

.item__link {
    display: flex;
}
.item__details {
    width: 90px;
    min-width: 110px;
    padding-right: 15px;
    text-align: center;

    .no-image {
        width: 100%;
        background-color: #fff;
        border: 1px solid rgba(204, 204, 204, 0.26);

        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: auto;
        padding: 1rem;
        color: #999;
    }
}
.item__thumb {
    max-width: 70px;
    max-height: 80px;
}

// Pedido

.modal__products {
    align-items: center;
    font-weight: 400;
    font-size: 1rem;
    .item__details {
        padding: 0.5rem;
    }
}

// File Upload
.custom-file-label::after {
    content: "Selecionar";
}

/* CKEDITOR */
.text-editor-mini {
    .ck.ck-content {
        min-height: 120px;
    }
}
.ck.ck-content {
    min-height: 300px;
}
.ck-widget {
    &.raw-html-embed {
        min-height: 40vh;

        .raw-html-embed__source {
            min-height: 36vh;
        }
    }
}
// Placeholder
.form-control {
    &::placeholder {
        color: #bbb;
        font-style: italic;
    }
}

// Titulos com link de ajuda
.title-with-helper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: -10px;
    h5 {
        margin-top: 0;
    }
}
.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.page-title-block {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 0.5rem 0;
    h1 {
        margin-top: 2rem;
    }
    .btn {
        float: right;
    }
}
.page-header-buttons {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.list-total {
    font-weight: bold;
    text-transform: capitalize;
    font-size: 1rem;
    color: #6c757d;
}

.floating-button-block {
    top: 15px;
    right: 30px;
    position: absolute;
    width: 100%;
    display: inline-block;
}

.pills-integracoes {
    .custom-form__card {
        height: 100%;
        .card-buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .btn-veja-como-funciona {
            margin-bottom: 0;
        }
    }
}
.img-logo-integracao {
    max-width: 40px;
    margin-right: 0.5rem;
    height: auto;
}

/* Pedidos */

.order-item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ddd;

    &:last-child {
        border-bottom: none;
    }
}

.orders__footer {
    .list-group-item {
        display: flex;
        justify-content: space-between;

        &.total-item {
            font-size: 1.25rem;
            font-weight: bold;
        }
    }
}

.tag-status {
    padding: 0.25rem 0.8rem;
    border-radius: 50px;
    font-size: 0.8rem;
}

.status-global {
    @extend .tag-status;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    width: fit-content;

    &.active {
        background-color: $color-success-bg;
        color: $color-success-dark;
        &:before {
            background-color: $color-success-dark;
        }
    }
    &.inactive {
        background-color: #f7f7f7;
        color: $color-secondary;
        &:before {
            background-color: $color-secondary;
        }
    }
}

.order-block {
    min-width: 170px;
}
.product-alert-devolucao {
    font-size: 0.8rem;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border-radius: 5px;
    width: auto;
    max-width: 300px;
}
.order-status {
    hr {
        border-color: #f5f5f5;
    }

    &--1 {
        background-color: $color-status-pendente-bg;
        color: $color-status-pendente;
    }
    &--2 {
        background-color: $color-status-processando-bg;
        color: $color-status-processando;
    }
    &--3 {
        background-color: $color-status-cancelado-bg;
        color: $color-status-cancelado;
    }
    &--4 {
        background-color: $color-status-concluido-bg;
        color: $color-status-concluido;
    }
    &--5 {
        background-color: $color-status-devolucao-bg;
        color: $color-status-devolucao;
    }
    &--6 {
        background-color: $color-status-orcamento-bg;
        color: $color-status-orcamento;
    }
    &--7 {
        background-color: $color-status-pago-bg;
        color: $color-status-pago;
    }

    .status__label {
        &--1 {
            color: $color-status-pendente;
        }
        &--2 {
            color: $color-status-processando;
        }
        &--3 {
            color: $color-status-cancelado;
        }
        &--4 {
            color: $color-status-concluido;
        }
        &--5 {
            color: $color-status-devolucao;
        }
        &--6 {
            color: $color-status-orcamento;
        }
        &--7 {
            color: $color-status-pago;
        }
    }
}
.payment-status {
    @extend .order-status;
    &--1 {
        background-color: $color-status-pendente-bg;
        color: $color-status-pendente;
    }

    &--2 {
        background-color: $color-status-pago-bg;
        color: $color-status-pago;
    }

    &--3 {
        background-color: $color-status-devolucao-bg;
        color: $color-status-devolucao;
    }
}

.order__edit {
    .form-buttons {
        margin: 0;
        display: flex;
        flex-direction: row;
        .btn {
            width: auto;
            margin-right: 0.5rem;
        }
    }
    .card-header {
        &--assinatura {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

.card-header {
    &--itens {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.card-dashboard {
    .icone {
        background: #66666629;
        border-radius: 100px;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        margin-right: 1rem;
    }
}

/* Assinatura */

.assinatura-status {
    hr {
        border-color: #f5f5f5;
    }
    &--1 {
        background-color: $color-status-iniciada-bg;
        color: $color-status-iniciada;
    }
    &--2 {
        background-color: $color-status-ativa-bg;
        color: $color-status-ativa;
    }
    &--3 {
        background-color: $color-status-suspensa-bg;
        color: $color-status-suspensa;
    }
    &--4 {
        background-color: $color-status-cancelada-bg;
        color: $color-status-cancelada;
    }
    &--5 {
        background-color: $color-status-atrasada-bg;
        color: $color-status-atrasada;
    }
    &--6 {
        background-color: $color-status-encerrada-bg;
        color: $color-status-encerrada;
    }
    .status__label {
        &--1 {
            color: $color-status-iniciada;
        }
        &--2 {
            color: $color-status-ativa;
        }
        &--3 {
            color: $color-status-suspensa;
        }
        &--4 {
            color: $color-status-cancelada;
        }
        &--5 {
            color: $color-status-atrasada;
        }
        &--6 {
            color: $color-status-encerrada;
        }
    }
}

.card__history {
    .note {
        font-size: 0.9rem;
        padding: 0.75rem 0;
        margin-bottom: 0.75rem;
        border-bottom: 2px solid #e5e5e5;
        border-radius: 0;

        p {
            margin-bottom: 0;
        }
        &:last-child {
            border: none;
        }
    }
}

.custom-card__new-item {
    border: 5px dashed #e5e5e5;
    text-align: center;
    padding: 2rem;
}

.dashboard-orders {
    padding: 0;
    font-size: 0.85rem;

    .order-card {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 0.35rem;
        padding-bottom: 0.35rem;
        border-bottom: 1px solid #f5f5f5;

        &:last-child {
            border-bottom: none;
        }
    }

    .order-header {
        font-weight: bold;
        margin-bottom: 1rem;
        background-color: #fff;
    }

    .info-block {
        width: 200px;
    }

    .status-block {
        align-self: center;
        display: flex;
        width: 170px;
        text-align: center;
    }
    .values-block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        width: 40%;
    }
}

.dashboard-status {
    font-size: 0.85rem;
    a {
        color: #333;
        font-size: 1.15rem;
        padding: 0;
    }
    hr {
        margin: 0.65rem 0;
        border-color: #f5f5f5;
    }
}

.dashboard-analytics {
    @extend .dashboard-orders;

    .item__name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        display: block;
        font-weight: 400;
        font-size: 0.8rem;
    }
    .order-card {
        margin-bottom: 0.15rem;
        padding-bottom: 0.15rem;
    }
}
.analytics-titulo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    img {
        max-width: 18px;
    }
}

.primeiros-passos {
    a {
        .svg-inline--fa {
            margin-right: 30px;
        }
    }
}

.overlayer-full {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    z-index: 9999;
    display: none;
    align-items: center;
    justify-content: center;

    &__content {
        display: flex;
        position: relative;
    }

    &.show-overlayer {
        display: flex;
    }
}

.textarea-custom {
    min-height: 40vh;
}

/* Assinatura */
.assinatura-temporaria {
    .table-products {
        box-shadow: none;
        thead {
            th {
                background-color: #fff;
                border-top: none;
            }
        }
        th {
            vertical-align: middle;
        }
        td {
            min-width: 1px;
            white-space: nowrap;
            select {
                min-width: max-content;
            }
        }
        .produto-nome {
            width: max-content;
            white-space: normal;
        }
        .input-quantidade {
            width: 130px;

            .input-group-text,
            input {
                background-color: #fff;
                text-align: center;
            }
        }
        .input-preco-bloco {
            min-width: max-content;
            .input-group {
                flex-wrap: nowrap;
            }
        }
        .form-group {
            margin: 0;
        }
        .bg-gray {
            background-color: #eeeeee;
        }
        .fixed-td {
            width: 0;
            white-space: nowrap;
        }
        .row-valores {
            background-color: #f3f4f5;
        }
    }
    .input-preco {
        max-width: 120px;
        float: right;
        text-align: right;
    }
    .total {
        font-size: 1.5rem;
    }
}

.filter-buttons {
    margin-top: 1.5rem;
    .btn {
        padding: 0.5rem 0.85rem;
    }
}

/* Entregas */

.mobile-bloco-acoes {
    min-width: 200px;
}

.modal-banner-modelo {
    .link-selecionar-banner {
        transition: all ease 0.5;
        width: 100%;
        height: 100%;
        display: block;
        border: 2px solid transparent;
        cursor: pointer;
        &:hover {
            opacity: 0.7;
        }
        &:checked {
            border-color: green;
        }
    }
}

.modal {
    img {
        max-width: 100%;
        height: auto;
    }
}

.modal-imagem {
    img {
        max-height: 68vh;
    }
}
.modal-high {
    z-index: 1058 !important;
}
.modal-higher {
    z-index: 1060 !important;
}

.table-with-dropdown {
    overflow: visible;
}

@media (max-width: 1199.98px) {
    .table-with-dropdown {
        overflow: auto;
    }
}

@media (max-width: 991.98px) {
    .page-title-block {
        h1 {
            margin-top: 1rem;
        }
    }
}

@media (max-width: 576px) {
    h1 {
        font-size: 1.35rem;
    }
    .btn-open-sidebar {
        left: 228px;
    }
    .list-total {
        font-size: 0.9rem;
    }
    .floating-button-block {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        margin-bottom: 0.5rem;
    }
    .page-header {
        display: block;
        .page-title-block {
            justify-content: flex-start;
            h1 {
                margin-top: 1rem;
            }
        }
        .page-header-buttons {
            display: flex;
            align-items: flex-start;
        }
    }

    .form-buttons {
        .btn {
            width: 48%;
        }
        &--3 {
            .btn {
                width: 30%;
                font-size: 0.85rem;
                padding: 0.5rem;
            }
        }
    }
    .item__details {
        min-width: auto;
        width: 70px;
        .item__thumb {
            max-width: 48px;
        }
    }
    .order__edit {
        .form-buttons {
            flex-direction: column;
            .btn {
                width: 0%;
                margin-bottom: 0.5rem;
            }
        }
        .card-header {
            &--assinatura {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
    .ui-widget {
        &.ui-widget-content {
            max-width: 77vw;
        }
    }

    .dashboard-orders {
        .order-card {
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 1.5rem;
            border-bottom: 1px solid #ddd;
            padding-bottom: 1rem;

            &:last-child {
                border-bottom: none;
            }
        }
        .order-header {
            display: none;
        }
        .info-block {
            width: 100%;
            margin-bottom: 0.5rem;
        }
        .status-block {
            margin-bottom: 1rem;
            display: block;
            width: auto;
            text-align: left;
        }
    }
    .dashboard-analytics {
        .order-card {
            padding-bottom: 0.25rem;
            margin-bottom: 0.25rem;
        }
    }
    .analytics-titulo {
        flex-direction: column;
        align-items: flex-start;
    }
    .primeiros-passos {
        li {
            flex-direction: column;
        }
    }

    .product__link {
        flex-direction: column;
        width: 200px;
    }
    .product__details {
        margin-bottom: 0.5rem;
    }

    .nav-pills {
        &.nav-pills-subitems {
            min-height: auto;
        }
    }

    .buttons-action-top {
        display: flex;
        width: 100%;

        .btn {
            text-align: center !important;
        }
    }
    .filter-buttons {
        margin-top: .5rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        .btn {
            max-width: 48%;
            gap: 8px;
        }
    }
    .modal__products {
        font-size: 0.9rem;
        .item__details {
            width: 80px;
        }
    }

}

.classe-teste-deploy {
    color: red;
}

.b-bg {
    background-color: $color-media-assinatura !important;
    color: $color-light;
}

.color-taxa-conversao {
    color: $color-success;
}

.bg-taxa-conversao {
    background-color: $color-success !important;
    color: $color-light;
}

.color-taxa-perda {
    color: $color-error;
}

.bg-taxa-perda {
    background-color: $color-error !important;
    color: $color-light;
}

.item-status-email {
    font-weight: bold;
    &.Enviado {
        color: $color-ok;
    }
    &.Falha {
        color: $color-error;
    }
}

.shadow-table {
    box-shadow: 0px 1px 3px $theme-text;
    border-radius: 5px;
    padding: 10px;
    tbody {
        tr {
            td {
                word-break: break-all;
            }
        }
    }
}

.table-layout-fixed {
    table-layout: fixed;
}

.nota-pedido {
    @page {
        size: portrait;
        width: 210mm;
    }
    margin: 10px;
    .cabecalho {
        padding: 0;
        margin: 0;
        border: 2px solid $black;
        .dados-loja {
            font-size: 14px;
            p {
                margin-top: 0;
                margin-bottom: 0.5rem;
            }
        }
        .pedido-valor {
            border-left: 2px solid $black;
        }
    }
    .sub-title {
        text-align: center;
        font-weight: bold;
    }
    .detalhes {
        text-align: center;
        font-size: 12px;
    }
    .info {
        border: 1px solid $gray;
        padding: 3px;
    }
    ul {
        padding-left: 0 !important;
        li {
            list-style: none;
            font-size: 14px;
        }
    }
    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .obs-pedido {
        font-size: 12px;
        text-align: justify;
        white-space: pre-wrap;
    }

    pre {
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
        text-align: left;
    }
}

.nota-oportunidade {

    @page {
        size: portrait;
        width: 210mm;
    }
    .cabecalho {
        margin: 0;
        border: none;
        border-bottom: 6px solid #e5e5e5;
        position: relative;
        margin-bottom: 2rem;

        .dados-loja {
            p {
                margin-top: 0;
                margin-bottom: 0.5rem;
            }
        }
        .pedido-valor {
            border-left: 2px solid $black;
        }
    }

    .dados {
        padding: 0;
        margin: 0;
        display: contents;
        min-height: 100px;
        p {
            margin-bottom: 0;
        }
        table {
            width: 100%;
            thead {
                text-transform: uppercase;
            }
        }
        .destaque {
            text-transform: uppercase;
            font-weight: 500;
        }
        .borda-inf {
            border-bottom: 2px solid #e5e5e5;
            thead {
                background-color: #e5e5e5;
            }
        }
        .totais {
            tr {
                td:last-child {
                    text-align: right;
                }
            }
        }
        .bloco-total {
            background-color: #e5e5e5;
            float: right;
            text-transform: uppercase;
            line-height: 1;
            display: flex;
            align-items: center;
            padding-left: 0.5rem 0.5rem 0.5rem 3rem;
        }
        .total {
            font-size: 1.5rem;
            padding-left: 1rem;
        }
        .cliente {
            margin-top: 3rem;
        }
    }

    .rodape {
        display: flex;
        justify-content: space-between;
        margin-top: 3rem;
        p {
            margin-bottom: 0;
        }
        .borda-sub {
            border-top: 2px solid $black;
        }
    }

    .sub-titulo {
        font-weight: bolder;
        text-transform: uppercase;
        margin: 2rem 0 .65rem 0;
        p {
            margin: 0;
        }
    }

    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;

        img {
            height: auto;
            max-height: 90px;
            max-width: 100%;
        }

    }

    .table-padding {
        th {
            font-weight: 500;
            padding: 3px 15px;
        }
        td {
            padding: 3px 15px;
        }
    }

    .obs-pedido {
        font-size: 16px;
        text-align: justify;
        white-space: pre-wrap;
    }

    pre {
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
        text-align: left;
    }
}

.link-completar {
    color:green;
}

.display-none {
    display:none;
}

.full-width {
    width:100% !important;
}

.texto-riscado {
    text-decoration: line-through;
}

.modal-escolha-templates {
    max-height: 700px;
    overflow-y: scroll;
}

.text-template-radio {
    cursor:pointer;
    font-size: 1.4rem;
}

$divRespostasRapidasRotatex: 180deg;
$divRespostasRapidasHeight: 30vh;
.js-div-respostas-rapidas-chat {

    width: 200px;
    height: 0;
    overflow: hidden;
    transform-origin: top;
    transition: height 0.3s ease-in-out;
    z-index:100;
    top: 0;
    transform: rotatex($divRespostasRapidasRotatex);
}

.expandir-div-respostas-rapidas {
    height: $divRespostasRapidasHeight;
}

.div-respostas-rapidas-normalizar-rotacao {
    max-height: $divRespostasRapidasHeight;
    overflow-x: hidden;
    overflow-y: scroll;
    transform: rotatex($divRespostasRapidasRotatex);
}

.js-mensagem-texto {
    resize: none;
}

.js-respostas-rapidas-resposta {
    cursor: pointer;
}

.modal-body-conflitos {
    max-height:700px;
    overflow-y:scroll;
}

.modal-97 {
    max-width: 97% !important;
}

.span-conflitos {
    width:100%;
    margin: 0 -15 0 0;
    border-radius:0;
}

.saida-qrcode canvas {
    display: block;
    margin: 0 auto;
    border: 0.2em solid #D0D0D0;
    border-radius: 0.4em;
}

.js-botao-calculadora-andaime {
    position: absolute;
    right: 0;
}

.tr-produtos-ccl-nao-selecionado {
    border-left: 7px solid white !important;
    background-color: white;
    color: #212529;
}

.tr-produtos-ccl-selecionado {
    border-left: 7px solid #00a771;
    background-color: $color-link;
    color: white;
}

.table-xs {
    font-size: 0.75rem;
    tr, td, select, input, span {
        font-size: 0.75rem;
    }
        .input-group-text {
            min-width: 37px !important;
            height: 32px !important;
        }
        input,
        .input-quantidade {
            max-width: 70px !important;
        }
        .badge {
            font-size: .5rem !important;
            font-weight: 500;
        }
}

.red-button {
    background: $color-error;
    color: $color-light;
}

.green-button {
    background: $color-success-200;
    color: $color-light;
}

.custom-alert {
    border-radius: 20px;
    padding: 0.3rem 0.5rem;
}

.centralizar-texto-y {
    display: flex;
    align-items: center;
    justify-content: center;
}

.texto-aviso {
    border-radius: 25px;
    text-align: center;
    font-weight: 600;
    .borda {
        border-radius: 25px;
        padding: 0.5rem 1rem;
    }
}

.alinhado-direita {
    float: right;
}

.texto-tracado {
    text-decoration: line-through;
}

.table-sibe-produtos {
    td, th {
        font-size: 0.7rem;
    }
}

.modal-lg,
.modal-xl {
    max-width: 1080px;
}

.header-chat {
    background-color: $gray3;
    .modal-header {
        background-color: $gray3;
    }
}

.table-modal-chat {
    .input-group, .form-group {
        margin: 0 !important;
    }
}

.sidebar-chat {
    .card-body {
        padding: 0 20px;
    }

    .table-responsive {
        margin: 20px;
    }

    .header-chat {
        background-color: $gray3;
    }

    .table-modal-chat {
        .input-group,
        .form-group {
            margin: 0 !important;
        }
    }

    .td-lateral {
        padding: 1rem 0;
    }

    .atualizar-produto-chat {
        cursor: pointer;
        &:hover {
            text-decoration: underline !important;
        }
    }

}

.icon-wrapper {
    position: relative;
    display: inline-block;
}

.estrela-overlay {
    position: absolute;
    top: -5px;
    right: -1px;
    width: 12px;
    height: 12px;
    pointer-events: none;
    filter: brightness(0) saturate(100%) invert(34%) sepia(96%) saturate(350%) hue-rotate(182deg) brightness(94%) contrast(94%);
}
