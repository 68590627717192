// Wizard
.wizard__progress-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
            text-transform: uppercase;
            font-size: 0.9rem;
            font-weight: bold;

            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    &__steps {
        display: flex;
        flex-direction: row;
    }
    .step {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        span {
            border: 1px solid;
            border-radius: 50px;
            width: 30px;
            height: 30px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0.5rem;
        }
        &.active {
            span {
                background-color: #333;
                color: #fff;
            }
        }
    }

    .link-tutorial {
        font-size: 1rem;
        svg {
            font-size: 1.5rem;
        }
    }
}

.form-wizard {
    .card-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .icon-option {
        color: $color-secondary;
        svg {
            color: inherit;
        }
    }
}

.wizard-question {
    font-size: 1.35rem;
    margin-top: 1rem;
}

.wizard__layout {
    height: auto;

    .browser-mockup {
        border-top: 2em solid rgba(230, 230, 230, 0.7);
        box-shadow: 0 0.1em 1em 0 rgba(0, 0, 0, 0.4);
        position: relative;
        border-radius: 3px 3px 0 0;
        margin: 1.5em;
        flex: 1;
        font-size: 0.85rem;

        &:before {
            display: block;
            position: absolute;
            content: "";
            top: -1.25em;
            left: 1em;
            width: 0.5em;
            height: 0.5em;
            border-radius: 50%;
            background-color: #f44;
            box-shadow: 0 0 0 1px #f44, 2em 0 0 1px #9b3, 1em 0 0 1px #fb5;
        }
        &.with-url:after {
            display: block;
            position: absolute;
            content: "https://sualoja.app.eloca.com.br";
            top: -2.3em;
            left: 7.5em;
            width: calc(100% - 9em);
            height: 1.75em;
            border-radius: 7px;
            background-color: #fff;
            color: #333;
            padding: 2px 7px;
            font-size: 0.6rem;
            font-weight: bold;
        }
    }

    .browser-mockup > * {
        display: block;
    }

    // Preview
    .layout {
        background-color: #fff;
        border: 1px solid #eee;
        color: #333;
        width: 100%;

        .promo {
            font-size: 90%;
            text-decoration: line-through;
            color: #999999;
            padding-right: 0.25rem;
        }

        .topbar {
            padding: 15px;
            border-bottom: 1px solid rgb(221 221 221 / 54%);
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 30px;
            font-size: 0.7rem;
            font-weight: bold;
        }
        .header {
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100px;
            .search {
                border-radius: 50px;
                background-color: #fff;
                border: 1px solid #ddd;
                width: 60%;
                height: 40px;
            }
        }
        .navbar {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 40px;
        }
        .banner {
            width: 100%;
            background-color: #ededed;
            color: #333;
            display: flex;
            align-items: center;
            justify-content: center;

            &--conteudo {
                display: flex;
                align-items: center;
                justify-content: center;
                background-image: linear-gradient(to right, #9c27b0, #e91e63);
                min-height: 180px;
                width: 80%;
                color: #fff;
            }
            .fluid {
                width: 100% !important;
                overflow: hidden;
            }
        }
        .banner-tarja {
            width: 100%;
            background-color: #ddd;
            color: #333;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            &--conteudo {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #ddd;
                height: 40px;
                width: 80%;
                font-style: italic;
            }
        }
        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            color: #333;
            min-height: 50px;
        }
        .bloco-conteudo {
            min-height: 80px;
            padding: 1rem;
            .card {
                height: 60px;
                background-color: #eee;
                border: 0;
                box-shadow: none;
            }
        }
        .btn-contato {
            width: 100%;
            color: #fff;
            border-radius: 50px;
            padding: 0.5rem;
            text-align: center;
        }
        .btn-rent {
            border-radius: 50px;
            padding: 0.25rem 1rem;
        }
        .titulo {
            font-size: 1rem;

            margin-bottom: 2rem;
            border-bottom: 1px solid #dddddd;
            padding-bottom: 1rem;
            font-weight: bold;
            color: var(--color-text);
            font-family: var(--font-primary);

            position: relative;

            .borda {
                content: " ";
                width: 4.56em;
                border-bottom: 2px solid transparent;
                border-color: var(--color-secondary);
                display: block;
                position: absolute;
                bottom: -1px;
            }
        }

        .categorias {
            .card {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
            }
        }

        .produto {
            .imagem {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 1rem;
            }
            .img-placeholder {
                min-height: 100px;
                text-transform: uppercase;
            }

            .loja-mista {
                &__radio {
                    border: 2px solid #dddddd;
                    padding: 0.5rem;
                    margin-bottom: 0.25rem;
                    border-radius: 0.25rem;
                }
            }

            .faixas {
                margin-bottom: 1rem;
                .faixas-campos {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }
                .faixas-blocos {
                    display: block;

                    .faixas-bloco {
                        display: flex;
                        flex-direction: row;
                        padding-left: 0.35rem;
                        .faixa-bloco {
                            border-radius: 50px;
                            padding: 0.2rem 0.55rem;
                            white-space: nowrap;
                            background-color: $color-primary;
                            color: #fff;
                        }
                    }
                }
                select {
                    background-color: #fdfdfd;
                    padding: 0 0.5rem;
                    border-color: #ededed;
                    color: #414141;
                    font-size: 0.8rem;
                }
            }
        }
        .footer {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: 100%;
            height: 100px;
            font-size: 0.8rem;
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .form-wizard {
        .card-footer {
            flex-direction: column-reverse;
            .btn {
                font-size: 0.85rem;
                width: auto;
                padding: 0.65rem;
                margin-bottom: 0.5rem;
            }
            .btn-block {
                width: 100%;
            }
            .btn-proximos {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                &--secondary {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                }
            }
        }
        .wizard-question {
            font-size: 1.25rem;
            line-height: 1.2;
        }
    }

    .wizard__progress-bar {
        flex-direction: column;
        align-items: flex-start;
        ul {
            li {
                flex-direction: column;
                align-items: flex-start;
            }
        }
        .link-tutorial {
            font-size: 0.9rem;
            svg {
                font-size: 1.25rem;
            }
        }
    }

    .wizard__layout {
        .browser-mockup {
            margin: 0;
            box-shadow: none;
            &.with-url:after {
                content: "";
            }
        }
        .layout {
            .categoria {
                margin-bottom: 0.5rem;
            }
            .footer {
                padding: 2rem;
            }
        }
        .preview {
            margin-top: 2rem;
        }
    }
}
