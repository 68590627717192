.btn {
    border-top-width: 0.025rem;
    border-bottom-width: calc(0.1rem + 0.025rem);
    border-radius: 0.35rem;
    padding: 0.45rem .75rem;
    font-weight: 500;
  //  font-size: .9rem;
}
.btn-success {
    border-color: $color-success-dark;
}
.btn-primary {
    border-color: #0d65b6;
}

.btn-outline-primary,
.btn-outline-secondary,
.btn-outline-success {
    background-color: #ffffff;
}
.btn-cancel {
    color: $color-secondary;
    background-color: #ffffff;
    border-color: #cdcdcd;
    &:hover {
        text-decoration: none;
        color: #ffffff;
        background-color: $color-secondary;
    }
}
.btn-light {
    border-color: #d6d6d6;
    color: $color-link;
    &:hover {
        color: $color-link;
    }
}
.btn-danger {
    border-color: #b52c39;
}
.btn-xs {
    font-size: 0.75rem;
   // padding: 0.5rem;
}

.btn-table {
    padding: .35rem 0.5rem;
}
.btn-icon-only {
    padding: 0.5rem 0.75rem;
}

.btn-log {
    position: fixed;
    right: 28px;
    bottom: 16px;
    z-index: 1000;
    text-align: center;
    background-color: #fff;
    border-color: #cdcdcd;
}

.btn-veja-como-funciona {
    padding: 0;
    float: right;
    margin-bottom: 0.5rem;
    border-bottom: none;
}

.btn-absolute-right {
    position: absolute;
    top: 50px;
    right: 30px;
    float: right;
}

.btn-delete {
    color: #333;
}

.btn-dropdown-telefone {
    background-color: transparent;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-width: 1px;
    border-color: #ced4da;
    display: flex;
    align-items: center;
    padding: 6px;

    &:hover {
        background-color: #e9ecef;
    }
}

@media (max-width: 767.98px) {
    .btn-log {
        right: 10px;
        float: right;
        top: 56px;
        width: 40px !important;
        height: 40px;
        padding: 0;
        border-radius: 100px;
    }
}

@media (max-width: 575.98px) {
    .btn {
        font-size: .8rem;
    }
    .btn-absolute-right {
        top: 66px;
        right: 25px;
        width: auto !important;
    }
}
