// Global styling for this template

html,
body {
  height: 100%;
}

// Set spacing on containers for page symmetry

.container,
.container-fluid {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}
